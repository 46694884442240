import React,{useEffect,useState} from 'react';
import { TextField, Input, Icon, Button } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';

import Navbar from "../../components/Navbar/Navbar";
import ProductTable from "../../components/table/ProductTable";
import { DecryptToken, FetchClients, FetchPrograms, FetchProjects } from '../../helper/BasicFn';
import styles from "./CreateProject.module.css";
import { CallGETAPI, CallPOSTAPI } from '../../helper/API';
import MessageHandler from '../../components/MessageHandler';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import logo from '../../img/logo.png';
import EditProjectModal from './EditProjectModal';
const ManageProjects = () => {
  const navigate = useNavigate();
  const [clientList,setClientList]          = useState([]);
  const [ProgramList,setProgramList]        = useState([]);

  const [selectedClient,setSelectedClient]      = useState("");
  const [selectedProgram,setSelectedProgram]    = useState("");
  const [loading,setLoading]                    = useState(false);
  const [edit,setEdit]                          = useState(false);
  const [editData,setEditData]                  = useState({});
  const [openEditModal,setOpenEditModal]        = useState(false);

  

  const [ProjectList,setProjectList] = useState([]);

  useEffect(()=>{
    program(clientList.find(a=>a.label==editData.client_name))
  },[editData.client_name])


  useEffect(()=>{
    setEditData({
      ...editData,
      program_name:selectedProgram
    })
    },[selectedProgram])

  useEffect(()=>{
    setEditData({
      ...editData,
      program_name:ProgramList.find(a=>a?.label==editData?.program_name)?.label
    })
    setSelectedProgram(ProgramList.find(a=>a?.label==editData?.program_name)?.label)
    // program(ProgramList.find(a=>a.label==editData.program_name))

  },[ProgramList])

  const program = async (ClientObj)=>{
    if(ClientObj?.value){
      setSelectedClient(ClientObj.value)
      let result = await FetchPrograms(ClientObj.value)
      if(result){
        let Programarr = [];
        for (let index = 0; index < result.length; index++) {
          const element = result[index];
          let obj = {
            label: element.program_name,
            value: element.id,
          }
          Programarr.push(obj)
        }
        setProgramList(Programarr)
      }
    }
    setEditData({
      ...editData,
      client_name:ClientObj.label
    })
  }

  const fetchData = async ()=>{
    let ClintData = await FetchClients();
    if(ClintData){
      let Clintarr = [];
      for (let index = 0; index < ClintData.length; index++) {
        const element = ClintData[index];
        let obj = {
          label: element.client_name,
          value: element.id,
        }
        Clintarr.push(obj)
      }
      setClientList(Clintarr)
    }
  }

  const GetProjects = async ()=>{
    const result = await FetchProjects();
    if(result){
      setProjectList(result)
    }
  }

  const [FormMsg,setFormMsg] = useState({type:false,msg:''});

  const handleSubmit = async (e)=>{
    setLoading(true)
    e.preventDefault();
    let userData = DecryptToken();
    let projectName = e.target.project_name.value;
    if(!userData) {
      setLoading(false)
      return '';
    };

    
    if(!selectedClient) {
      setLoading(false)
      setFormMsg({type:false,msg:'Please Select Client...'})
      return '';
    };
    if(!selectedProgram) {
      setLoading(false)
      setFormMsg({type:false,msg:'Please Select Program...'})
      return '';
    };
    
    if(!projectName) {
      setLoading(false)
      setFormMsg({type:false,msg:'Please Enter Project Name...'})
      return '';
    };
    let sendObj = {
      "clientId": selectedClient,
      "programId": ProgramList.find(a=>a.label === selectedProgram ).value ,
      "projectName": projectName ,
      "userId": userData.id
    }
    let result
    if(edit){
      sendObj.projectId = edit
      delete sendObj.userId
      result = await CallPOSTAPI('update-project-name',sendObj);
    }else{
      result = await CallPOSTAPI('save-project-name',sendObj);
    }

    setLoading(false)

    if(result.status){
      if(result.data.status){
        toast.success(result.data.msg);
        navigate("/create-serial-number");
        setLoading(false)
        setEditData({})
        setEdit(false)
        return;
      }
      setFormMsg({type:result.data.status,msg:result.data.msg})
    }
  }

  useEffect(()=>{
    fetchData();
    GetProjects();
  },[]) 
  return (
    <div className={styles.loginDiv}>
      <main className={styles.main}>
        <h2 className={styles.heading}>My Projects</h2>
        <div className={styles.container} >
        
        <div className='' >

          <div className={styles.tableDiv} >
              <ProductTable TableTile="All Projects" TableData={ProjectList} clientList={clientList} GetProjects={GetProjects} setEdit={setEdit} setEditData={setEditData} openEditModal={openEditModal} setOpenEditModal={setOpenEditModal}/>
          </div>
        </div>
        </div>

        <EditProjectModal formEditData={editData} setFormEditData={setEditData} openEditModal={openEditModal} setOpenEditModal={setOpenEditModal}/>
      </main>
    </div>
  );
};

export default ManageProjects;
