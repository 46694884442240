import './Projects.scss';
import React from 'react'
import Navbar from '../../components/Navbar/Navbar';
export default function AllProjects() {
    const [ProjectList,setProjectList] = React.useState(
        [
            {title: "Project Title three dots...",pDate:'11 Nov 2022', pId:1,status:'Exportad'},
            {title: "Project Title three dots...",pDate:'11 Nov 2022', pId:1,status:'Exportad'},
            {title: "Project Title three dots...",pDate:'11 Nov 2022', pId:1,status:'New'},
            {title: "Project Title three dots...",pDate:'11 Nov 2022', pId:1,status:'Exportad'},
            {title: "Project Title three dots...",pDate:'11 Nov 2022', pId:1,status:'Exportad'},
            {title: "Project Title three dots...",pDate:'11 Nov 2022', pId:1,status:'Exportad'},
            {title: "Project Title three dots...",pDate:'11 Nov 2022', pId:1,status:'Exportad'},
            {title: "Project Title three dots...",pDate:'11 Nov 2022', pId:1,status:'Exportad'},
            {title: "Project Title three dots...",pDate:'11 Nov 2022', pId:1,status:'Exportad'},
            {title: "Project Title three dots...",pDate:'11 Nov 2022', pId:1,status:'New'},
            {title: "Project Title three dots...",pDate:'11 Nov 2022', pId:1,status:'Exportad'},
            {title: "Project Title three dots...",pDate:'11 Nov 2022', pId:1,status:'Exportad'},
        ]);

  return (
    <>

    <div className='all-projects' >
        <h1  className="section-title">All Projects</h1>
        <div className='flex-box' >
            {ProjectList.map((Project,index)=>(
                <div className='box' key={index} >
                    <h4>{Project.title}</h4>
                    <div className='foot-box' >
                        <small>{Project.pDate}</small>
                        <button className={Project.status} >{Project.status}</button>
                        
                    </div>
                </div>
            ))}
        </div>
    </div>
    </>
  )
}
