import React, { useState } from "react";
import styles from "./profile.module.css";
import MessageHandler from "../../components/MessageHandler";
import { Button, Icon, TextField } from "@mui/material";
import { CallPOSTAPI } from "../../helper/API";
import { toast } from 'react-toastify';


const EditPassword = () => {
  const [FormMsg, setFormMsg] = useState({ type: false, msg: "" });
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    let oldPassword = e.target.oldPassword.value.trim();
    let newPassword = e.target.newPassword.value.trim();

    const sendObj = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };

    if(sendObj?.oldPassword.length == 0 || sendObj?.newPassword.length == 0){
        setFormMsg({
            type:false,
            msg:'Any field not be Empty'
        })
        return
    }
    if(sendObj?.oldPassword == sendObj?.newPassword){
        setFormMsg({
            type:true,
            msg:'Old password & New password not must be same'
        })
        return
    }
    if(sendObj?.oldPassword.length == 0){
        setFormMsg({
            type:true,
            msg:'Old password not must be Empty'
        })
        return
    }if(sendObj?.newPassword.length == 0){
        setFormMsg({
            type:true,
            msg:'New password not must be Empty'
        })
        return
    }

    let result = await CallPOSTAPI("change-password", sendObj);
    if(result.status){
        if(result.data.status){
          toast.success("Password change sucessfully");
          setLoading(false)
          return;
        }
        setFormMsg({type:result.data.status,msg:"Password change sucessfully"})
      }
  };
  return (
    <div className={styles.loginDiv}>
      <main className={styles.main}>
        <h2 className={styles.heading}>Update Password</h2>
        <div className={styles.containerr}>
          <div>
            <form className={styles.grid} onSubmit={handleSubmit}>

            <div className={styles.titlediv}>
                {/* <div className={styles.title}>Old Password</div> */}
              <TextField
                className={styles.password}
                sx={{ width: "100%" }}
                color="primary"
                variant="filled"
                type="text"
                name="oldPassword"
                label="Old Password"
                size="medium"
                margin="none"
                
              /></div>

              <div className={styles.titlediv}>
                {/* <div className={styles.title}>New Password</div> */}
              <TextField
                className={styles.password}
                sx={{ width: "100%" }}
                color="primary"
                variant="filled"
                type="text"
                name="newPassword"
                label="New Password"
                size="medium"
                margin="none"
                
              /></div>
              <MessageHandler
                status={FormMsg.type}
                msg={FormMsg.msg}
                HandleMessage={setFormMsg}
              />
              <Button
                className={styles.button2}
                type="submit"
                endIcon={<Icon>arrow_forward_ios_sharp</Icon>}
              >
                {loading ? "Loading..." : "Update Password"}
              </Button>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

export default EditPassword;
