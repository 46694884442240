import React from 'react';
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Login from "./pages/login/Login";
import { useEffect } from "react";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import Dashboard from "./pages/dashboard/Dashboard";
import CreateProject from "./pages/createProject/CreateProject";
import SerialNumber from "./pages/createSerialNumber/SerialNumber";
import AllProjects from "./pages/Projects";
import SingleProject from "./pages/Projects/SingleProject";
import Layout from "./components/layout";
import AllSrNo from './pages/createSerialNumber/AllSrNo';

import 'semantic-ui-css/semantic.min.css'

import 'devextreme/dist/css/dx.light.css';
import SrDetails from './pages/createSerialNumber/SrDetails';
import Profile from './pages/profile/profile';
import ResetPassword from './pages/profile/resetPassword';
import ManageProjects from './pages/createProject/ManageProjects';
import AllCleints from './pages/Clients/AllCleints';


function App() {
  const action = useNavigationType();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const [isLogin,setisLodin] = React.useState(false);
  
  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
    
  }, [action]);

  useEffect(() => {
    let title           = "";
    let metaDescription = "";

    //TODO: Update meta titles and descriptions below
    switch (pathname) {
      case "/":
        title = "QC : : Home";
        metaDescription = "QC-Login";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  const [title,setTitle] = React.useState('Dashboard');


  const layoutWrapper = (props)=>{
    return (<Layout pageTitle={title}>{props}</Layout>)
  }


  
  useEffect(()=>{
    let token = sessionStorage.getItem('qc_token');
    if(token){
      setisLodin(true)
    }else{
      console.log(navigate.pathname)

      if(navigate.pathname==='/forgot-password'){
          console.log(navigate.pathname)
      }
      setisLodin(false)
      // navigate('/')
    }
  },[navigate])


  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />

      {isLogin && <>
        <Route path="/dashboard" element={layoutWrapper(<Dashboard />)} />
        <Route path="/profile" element={layoutWrapper(<Profile />)} />
        <Route path="/create-project" element={layoutWrapper(<CreateProject />)} />
        <Route path="/project-list" element={layoutWrapper(<ManageProjects   />)} />
        <Route path="/my-project" element={layoutWrapper(<ManageProjects />)} />
        <Route path="/create-serial-number" element={layoutWrapper(<SerialNumber />)} />
        <Route path="/edit-serial-number" element={layoutWrapper(<SerialNumber showDropDown={true} />)} />

        <Route path="/all-projects" element={layoutWrapper(<AllProjects  />)} />
        <Route path="/single-project" element={layoutWrapper(<SingleProject />)} />
        <Route path="/clients"    element={layoutWrapper(<AllCleints />)} />
        <Route path="/project-details/:id" element={layoutWrapper(<AllSrNo  />)} />
        <Route path="/srno-details/:id"    element={layoutWrapper(<SrDetails />)} />
      </>}
    </Routes>
  );
}
export default App;
