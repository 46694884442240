import { Button, Icon, TextField } from "@mui/material";
import React from "react";
import MessageHandler from "../../components/MessageHandler";
import { CallPOSTAPI } from "../../helper/API";
import styles from "./resetPassword.module.css";
import logo from '../../img/hydro-logo.png';
import { toast,ToastContainer } from 'react-toastify';
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";


const ResetPassword = () => {
  const [FormMsg, setFormMsg] = React.useState({ type: false, msg: "" });
  const [loading, setLoading] = React.useState(false);
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');
  const navigate = useNavigate()

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let newPassword = e.target.newPassword.value;
    let confirmPassword = e.target.confirmPassword.value

    if (newPassword.length == 0 || confirmPassword.length == 0) {
        setFormMsg({
            type:false,
            msg:'Any field not be Empty!'
        })
        return
    }if (newPassword !== confirmPassword) {
        setFormMsg({
            type:false,
            msg:'Password must be same!'
        })
        return
    }
    

    let result = await CallPOSTAPI(`update-password?token=${token}`, {"newPassword":newPassword});

    if (result.data.status) {
        toast.success("New Password set sucessfully");
        setFormMsg({
          type:true,
          msg:result.data.msg
      })

        setTimeout(() => {
          navigate("/")
        }, 4000);
        return;
    }else {
        setFormMsg({
            type:false,
            // msg:'Password reset unsucessfull'
            msg:result.data.msg
        })
    }
    setLoading(false)

  };

  return (
    <div className={styles.loginDiv}>
      <main className={styles.main}>
        <a className={styles.logoA}>
          <div className={styles.yourLogoDiv}>
          <img src={logo} className={'form-brand'} />
          </div>
        </a>
        <span>Forgot Password</span>
        <form className="" id="forgot-password"  onSubmit={HandleSubmit}>
          <div>
            <TextField
              className={styles.userNameTextField}
              sx={{ width: 499 }}
              color="primary"
              variant="filled"
              type="password"
              label="New Password"
              size="medium"
              margin="none"
              name="newPassword"
              
            />
          </div>

          <div className={styles.TextField}>
            <TextField
              className={styles.userNameTextField}
              sx={{ width: 499 }}
              color="primary"
              variant="filled"
              type="password"
              label="Confirm Password"
              size="medium"
              margin="none"
              name="confirmPassword"
              
            />
          </div>

        <div className={styles.TextField}>
          <MessageHandler
            status={FormMsg.type}
            msg={FormMsg.msg}
            HandleMessage={setFormMsg}
          />
          </div>

          <div className={styles.btn} >
            <Button
              className={styles.muibtn}
              variant="contained"
              color="primary"
              type="submit"
              endIcon={<Icon>arrow_forward_ios_sharp</Icon>}
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
            {/* <Button>Back</Button> */}
          </div>
        </form>
        
        <div className="">
          <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's </p>
        </div>

       
        <ToastContainer/>
      </main>
    </div>
  );
};

export default ResetPassword;
