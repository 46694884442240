import { TextField, Input, Icon, Button } from "@mui/material";
import styles from "./Dashboard.module.css";
import Navbar from "../../components/Navbar/Navbar"
import { Link } from "react-router-dom";
import { DecryptToken, UserDetails } from "../../helper/BasicFn";
import React from "react";
import Logo from '../../img/hydro-logo.png';
import { Statistic } from 'semantic-ui-react'
import { CallGETAPI } from "../../helper/API";
import { Person2 } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import GridOnIcon from '@mui/icons-material/GridOn';
const Dashboard = () => {
  const [userInfo,setUserInfo] = React.useState({});
  const [dashboardContent,setDashboardContent]  = React.useState([]);
  const [isActive,setIsActive] = React.useState(false);

  const fecthData = async()=>{
    let res = await CallGETAPI('get-created-projects');
    if(res?.data.status){
      setIsActive(true)
      setDashboardContent(res?.data)
    }
  }
  React.useState(()=>{
    let userInfoData = DecryptToken();
    setUserInfo(userInfoData)
    fecthData();
  },[])

 
  return (
    <>

    <div className={styles.loginDiv}>


      
      <div className={styles.cardDesign}>

      <div className="dashboard-anlytics" >

      <div className="dashboard-card one" >
        <div className="icon-div" >
            <AddIcon className="icon"/>
        </div>        
        <div className="text-dv" >
          <span className="count">
          {isActive > 0 && 
            <Statistic.Value>{dashboardContent?.data2[0]?.total_created_projects}</Statistic.Value>
            }
          </span>
          <p className="text">Created projects</p>
        </div>
      </div>

      <div className="dashboard-card two" >
        <div className="icon-div" >
            <GridOnIcon className="icon"/>

        </div>        
        <div className="text-dv" >
          <span className="count">
          {isActive > 0 && 
            <Statistic.Value>{dashboardContent?.data[0]?.total_exported_projects}</Statistic.Value>
            }
          </span>
          <p className="text">Exported Projects</p>
        </div>
      </div>

      <div className="dashboard-card three" >
        <div className="icon-div" >
            <CloudDownloadIcon className="icon"/>
        </div>        
        <div className="text-dv" >
          <span className="count">
          {isActive > 0 && 
            <Statistic.Value>{dashboardContent?.data1[0]?.total_open_projects}</Statistic.Value>
            }
          </span>
          <p className="text">Open projects</p>
        </div>
      </div>

      </div>
        

       
      </div>
    
    <div className={styles.DashboardContainer}>
      <main className={styles.main}>
        <img src={Logo} className={'form-brand'} />
        <span className={styles.welcomeText}>Welcome {userInfo?.Name}</span>
        {/* {JSON.stringify(userInfo)} */}
        {/* <span className={styles.userName}>{userInfo?.Name}</span> */}
    
        <Link to="/create-project" className="create-project" >
          <Button className={styles.button}
            endIcon={<Icon>arrow_forward_ios_sharp</Icon>}
            >
            Generate smart Check List
          </Button>
          
        </Link>

        <div className="">
          <p> {window?.GLOBAL_TIPS?.dashboard} </p>
        </div>

        
{/*        
        <div className={styles.bottomLinksDiv}>

          <a className={styles.termsOfUse} href="#">
            Terms of Use
          </a>
          
          <a className={styles.termsOfUse} href="#">
            Privacy Policies
          </a>
          
          <a className={styles.termsOfUse} href="#">
            Create a New Account
          </a>
          
          <a className={styles.termsOfUse} href="#">
            Cookies Policies
          </a>
        </div>

         */}
      </main>
    </div>
    </div>
    </>
  );
};

export default Dashboard;
