import React,{useState,useEffect} from 'react';
import Box                        from '@mui/material/Box';
import Modal                      from '@mui/material/Modal';
import EdistErNoForm              from './EdistErNoForm';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };



export default function SrEditModal({openModal,closeModal,editFormData}) {

// const [open, setOpen] = React.useState(false);

// const handleOpen      = () => setOpen(true);
// const handleClose     = () => setOpen(false);

  return (
    <>
        <div className='' > 
        <Modal
            open={openModal}
            onClose={closeModal}
            aria-labelledby="srno-title"
            aria-describedby="srno-description"
        >
          <Box sx={style}>
            {/* <pre>
              {editFormData ? editFormData : ''}
            </pre> */}
            {/* <SerialNumberForm editFormData={editFormData}/> */}
            <EdistErNoForm editFormData={editFormData}/>
          </Box>
      </Modal>


        </div>
    </>
  )
}
