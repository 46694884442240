// const BASE_API = 'https://qc.tcdesigners.online/api/user/';
// export const BASE_API = 'https://qc.tcdesigners.online/api/user/';
// export const IMG_URL = 'https://qc.tcdesigners.online';
// export const IMGURL2 = 'https://qc.tcdesigners.online/uploads/'

export const BASE_API = 'https://admin.hydro10consulting.com/api/user/';
export const IMG_URL = 'https://admin.hydro10consulting.com/';
export const IMGURL2 = 'https://admin.hydro10consulting.com/uploads/'


export async function CallGETAPI(url,params="",headers={}){
    try{
    let token  = sessionStorage.getItem('qc_token');
    let AllHeaders = {...headers};
    if(token){
        AllHeaders = {Authorization: 'Bearer '+token,...headers}
    }
    // let token = sessionStorage.getItem('')
        let res = await fetch(BASE_API + url + params,{
            method:'GET',
            headers:{
                'Content-Type':'application/json',...AllHeaders}
        });

        if(!res.ok){
            return {status:false,msg:'No Data Found',data:[]}
        }
        let resultData = await res.json();
        return {status:true,msg:'',data:resultData}
    }catch(e){
        return {status:false,msg:e.message,data:[]}
    }
}


export async function CallPOSTAPI(url,params=[],headers={},formData){
    try{
        
        let token  = sessionStorage.getItem('qc_token');
        let AllHeaders = {...headers};
        if(token){
            AllHeaders = {Authorization: 'Bearer '+token,...headers}
        }

        let res = await fetch(BASE_API + url,{
            method:'POST',
            headers:{'Content-Type':'application/json',...AllHeaders},
            body: formData ? formData : JSON.stringify(params)
        });

        if(!res.ok){
            return {status:false,msg:'No Data Found',data:[]}
        }
        let resultData = await res.json();
        return {status:true,msg:'',data:resultData}
    }catch(e){
        return {status:false,msg:e.message,data:[]}
    }
}