import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom';
import SrNoTable from './SrNoTable';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField, Input, Icon, Button } from "@mui/material";
import { fecthScopDetails, fecthScopItem, FetchProjects } from '../../helper/BasicFn';


export default function AllSrNo({showDropDown}) {
    const params = useParams();
    const {id} = params;
    const [SrnNoList,setSrnoList] = useState([]);
  const [ProjectList,setProjectList]            = useState([])
  const [selectedProject,setSelectedProject]    = useState('');

  const [editData, setEditData] = useState('');
  const [isEdit,setIsEdit]      = useState(false);
  const [edit,setEdit]          = useState('');


  const [drop,setdrop]  = useState('');

    const FetchData = async ()=>{
        let result = await fecthSrNo(id);
        if(result){
          setSrnoList(result)
        }
    }

    const FetchProjectList = async ()=>{
      let result = await FetchProjects();
      if(result){
        let arr = [];
        for (let index = 0; index < result.length; index++) {
          const element = result[index];
          let obj = {
            value : element.pn_id,
            label:element.project_name
          }
          arr.push(obj);
        }
        setProjectList(arr);
        HandleProjectList(arr?.[0])
        console.log(arr?.[0])
      }
    }


    useEffect(()=>{
      FetchData();
      FetchProjectList();
    },[])
    const HandleProjectList = async (singleProject)=>{
      // FetchScopeItems(singleProject.value);
      setSelectedProject(singleProject.value)
      setdrop(singleProject.label)
    }
  return (
    <>
        {showDropDown && <>
          <h4> Edit Scope Details </h4>
            <Autocomplete
              id="combo-box-demo"
              value={drop}
              options={ProjectList}
              onChange={(event, newValue) => HandleProjectList(newValue) }
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} label="Select Project" />}
            />
            <br/>
        </>}
        
        <SrNoTable ProjectId={id || selectedProject}
        setEditData={setEditData} setEdit={setEdit} setIsEdit={setIsEdit}
        showDropDown={showDropDown} isRenderAgain={''}
        
        />
    </>
  )
}
