import { CallGETAPI, CallPOSTAPI } from "./API";

export const UserDetails = async ()=>{
    let result = await CallGETAPI('user-profile')
    if(result.status){
        if(result?.data.status){
            return result?.data?.data[0]
        }
    }
    return false;
}


export const DecryptToken = ()=>{
    let token = sessionStorage.getItem('qc_token');
    
    if(!token){ return '';}

    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}

export const FetchClients = async ()=>{
    let result = await CallGETAPI('assignedClients');
    if(result.status){
        return result?.data?.data; 
    }
    return '';
}

export const FetchPrograms = async (clientID)=>{
    let result = await CallGETAPI('program-by-client/'+clientID);
    if(result.status){
        return result?.data?.data; 
    }
    return '';
}

export const FetchProjects = async ()=>{
    let result = await CallGETAPI('all-project-by-user');
    if(result.status){
        return result?.data?.data; 
    }
    return '';
} 

export const GetSingleProject  = async (id)=>{
    let result = await CallGETAPI('get-project-by-id/'+id);
    if(result.status){
        return result?.data?.data; 
    }
    return '';   
}

export const fecthScopItem = async (id)=>{
    let result = await CallGETAPI('all-scope-items/'+id);
    if(result.status){
        return result?.data?.data; 
    }
    return '';
}

export const fecthScopDetails = async (data)=>{
    let result = await CallPOSTAPI('all-scope-detail',data);
    if(result.status){
        return result?.data?.data; 
    }
    return '';
}

export const fecthSrNo = async (id)=>{
    // let result = await CallGETAPI('get-serial-number-by-id/'+id);
    let result = await CallGETAPI('serial-numbers-by-project/'+id);
    if(result.status){
        return result?.data?.data; 
    }
    return '';
}

export const GetSrNOById = async (id)=>{
    let result  = await CallGETAPI('get-serial-number-by-id/'+id);
    if(result.status){
        return result?.data?.data[0]; 
    }
    return '';
}

export const AllSrNoFN = async ()=>{
    let result = await CallGETAPI('all-serial-numbers/');
    if(result.status){
        return result?.data?.data; 
    }
    return '';
}


