import React,{useState,useEffect} from 'react'
import { AllSrNoFN, fecthSrNo, GetSingleProject } from '../../helper/BasicFn';
import DataGrid, { Column,SearchPanel } from 'devextreme-react/data-grid';
import { useNavigate } from 'react-router-dom';

import EYEIcon from '../../img/eye.png';
import EditIcon from '../../img/edit.png';
import TrashIcon from '../../img/trash.png';
import { CallGETAPI, CallPOSTAPI } from '../../helper/API';
import { toast } from 'react-toastify';
// import axios from 'axios';
import SrEditModal from './SrEditModal';
import SrDetailModals from './SrDetailModals';
import Swal from 'sweetalert2';
import useFileDownloader from '../../components/download/useFileDownloader';
import Downloader from '../../components/download';


export default function SrNoTable({ProjectId,setEditData,setEdit,showDropDown,setIsEdit,isRenderAgain}) {
    const navigate                                  = useNavigate();
    const [SrNoList,setSrNoList]                    = useState([]);
    const [currentProject,setCurrentProject]        = useState([]);
    const [enableExport,setEnableExport]            = useState(true);
    const [openEditModal,setOpenEditModal]          = useState(false);
    const [openDetailsModal,setOpenDetailsModal]    = useState(false);
    const [editFormData,setEditFormData]            = useState('');

    const handleEditModal = ()=>{
        setOpenEditModal(!openEditModal)
    }

    const handleDetailsModal = ()=>{
        setOpenDetailsModal(!openDetailsModal)
    }

    const fetchSr = async ()=>{
        let result  = await AllSrNoFN();
        if(result){
            setSrNoList(result);
        }
    }

    const FetchData = async ()=>{
        let ProjectData = await GetSingleProject(ProjectId);
        if(ProjectData){
            setCurrentProject(ProjectData)
        }


        let result = await fecthSrNo(ProjectId);
        if(result && result?.length > 0){            
            setEnableExport(false)
            setSrNoList(result)
        }
        else {
            setSrNoList([])
 
        }
    }


    useEffect(()=>{
        if(ProjectId){
            FetchData();
        }else{
            // fetchSr();
        }
    },[ProjectId,isRenderAgain])





    const MakeEdit = (eid,edata) => {
        // if(setEditData){
            // setEditData(edata);
        // }

        setIsEdit(true);
        let ojb = {
            value:edata.data.sn_id,
            title:edata.data.serial_number,
            data: edata.data
        }
        setEditFormData(ojb)
        setOpenEditModal(true);
        setEdit(edata.data.sn_id);
        // window.scrollTo({top:0,behavior:"smooth"})
        // setOpenEditModal(true);
        // setEditProgram(eid);
    }


    const askAlert  = (did,srno)=>{
        Swal.fire({
            title: 'Are you sure?',
            text: "if you are sure you want to delete Serial Number '"+srno+"'",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {

                MakeDelete(did)

            }
          })
    }


    const MakeDelete =  async (did)=>{

        // let check = confirm('Are you Sure?, You want to Delete')
        // if(!check){
        //     return;
        // }
        let result = await CallGETAPI('delete-serial-number/'+did);
        if(result?.data?.status){
            toast.success(result?.data?.msg)
            // await FetchData();
            if(ProjectId){
                FetchData();
            }else{
                // fetchSr();
            }
            // await GetProjects();
        }else{
            toast.error(result.data.msg)
        }
    }      

    const ShowDetails = (id)=>{
        navigate('/srno-details/'+id)
    }



    const GetAction =  (Data)=>{
        let id =  Data.data['sn_id'];
        let srno =  Data.data['serial_number'];
        return (<> 
            <div className='d-flex  edit-icons ' >                
            <span className='d-a-tag pointer'  onClick={()=>MakeEdit(id,Data)}title="edit"> 
                <img src={EditIcon} alt="Edit" />
            </span>
            <span className='a-tag pointer' onClick={()=>ShowDetails(id)} title="view">
                <img src={EYEIcon} alt="Eye" />
            </span>
            <span className='a-tag pointer' onClick={()=>askAlert(id,srno)} title="remove" >
                <img src={TrashIcon} alt="Trash" />
            </span>
            </div>
            </>)
    }

    const [Loading,setLoading] = useState(false);

    const ExportCSV = async()=>{

        if(!ProjectId){return;}
        if(!SrNoList){return;}

        setLoading(true)
        setEnableExport(true)

        let result  = await CallGETAPI('export-excel/'+ProjectId)
        if(result?.data?.status){
             DownloadFile(result?.data?.file)
        }else{
            toast.error(result?.data?.msg)
        }

        setLoading(false)
        setEnableExport(false)

    }


    const { files, downloadFile, remove } = useFileDownloader();
    const DownloadFile2 = (file) => {
        console.log(file)
      let obj = {
        name: 'DownloadFile.xls',
        file: file,
        filename: "DownloadFile.xls",
      };
      if (file) {

        downloadFile(obj);

      } else {
        toast.warning("File Not Found", {
          progress: undefined,
          autoClose: 5000,
        });
      }

    };

    const DownloadFile = async (fileurl) => {
//   application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
let fileName = currentProject[0]?.project_name;

let alink = document.createElement('a');
    alink.href = fileurl;

    // console.log('heloga')

    alink.click();

/* let headers = {
    'Content-Type': 'application/vnd.ms-excel',
    }
axios({
    url: fileurl,
    method: "GET",
    headers: headers,
    responseType: "blob" // important
}).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
        "download",
        `${fileName}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
});
 */
        // alink.download = 'Sample.xlsx';
// fetch(fileurl).then(response => {
//     response.blob().then(blob => {
//         // Creating new object of PDF file
//         const fileURL = window.URL.createObjectURL(blob);
//         // Setting various property values
//         let alink = document.createElement('a');
//         alink.href = fileURL;
//         // alink.download = 'Sample.xlsx';
//         alink.click();
//     })
// })

        /* fetch(fileurl, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/vnd.ms-excel',
                },
  })
  .then((response) => response.blob())
  .then((blob) => {
    // Create blob link to download

    fileName = fileName.replace(/ /g,"_");
    fileName = fileName.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '_');


    

    const url = window.URL.createObjectURL(
      new Blob([blob]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download',`${fileName}.xlsx`,);
    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  }); */

    }

  return (
    <>

    <div className='table-section-div'  >

    <div className='table-header' >
        {/* {currentProject.length > 0 ? currentProject[0]?.program_name : 'Sr. Numbers' }  */}
        <span>
            {currentProject.length > 0 && <>Sr. Numbers : <b>{currentProject[0]?.project_name}</b></>}
        </span>

        
        {ProjectId ? <button className={`export-btn ${enableExport?'desable':'enable'} `} onClick={ExportCSV} disabled={enableExport} >
            {Loading?'Loading...':'Export CSV'}
        </button>:''}
    </div>
    
    <div className='td-main' >

    <DataGrid
            dataSource={SrNoList}
            keyExpr="sn_id"
            showRowLines={true}
            className="data-grid-table"
            columnAutoWidth={true}
            allowColumnReordering={true}
            showColumnLines={true}
            showBorders={false}
            wordWrapEnabled={true}            
        >

            <SearchPanel visible={true} />

            {/* <Column 
                dataField="project_name"
                caption={'Project Name'}
                dataType="string"
                width={220}
            /> */}
          
            
            <Column 
                dataField="serial_number"
                caption={'Serial Number'}
                dataType="string"
            />
             <Column 
                dataField="scope_item_title"
                caption={'Scope Item'}
                dataType="string"

            />

            <Column 
                dataField="scope_detail_title"
                caption={'Scope Details'}
                dataType="string"

            />
           

            <Column 
                caption={'Action'}
                cellRender={(data)=>GetAction(data)}
                
            />

        </DataGrid>

    </div>

            <SrEditModal 
            openModal={openEditModal} 
            closeModal={handleEditModal}  
            editFormData={editFormData}
            
            
            />
            <SrDetailModals openModal={openDetailsModal} closeModal={handleDetailsModal} />
        </div>

        {showDropDown && <>
        
            
        </>}

        <Downloader 
        files={files} 
        remove={remove}
        />

    </>
  )
}
