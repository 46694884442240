import React, {useState,useEffect} from 'react';
import { TextField, Input, Icon, Button } from "@mui/material";
import styles from "./SerialNumber.module.css";
import Navbar from "../../components/Navbar/Navbar"
import Autocomplete from '@mui/material/Autocomplete';
import { fecthScopDetails, fecthScopItem, fecthSrNo, FetchProjects, GetSrNOById } from '../../helper/BasicFn';
import { CallGETAPI, CallPOSTAPI } from '../../helper/API';
import logo from '../../img/logo.png';

import MessageHandler from "../../components/MessageHandler";
import SrNoTable from './SrNoTable';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


const EdistErNoForm = ({showDropDown,editFormData}) => {
  const navigate  = useNavigate();

  const [FormMsg,setFormMsg]                    = React.useState({type:true,msg:""});
  const [loading,setLoading]                    = React.useState(false);


  const [ProjectList,setProjectList]            = useState([])
  const [ScopeItemsList,setScopeItemsList]      = useState([]);
  const [ScopeDet,setScopeDet]                  = useState([])
  const [selectedProject,setSelectedProject]    = useState('');
  const [SelectedScopItem,setSelectedScopItem]  = useState('');
  const [selectedScopDet,setSelectedScopDet]    = useState('');
  const [edit,setEdit]          = useState('');
  const [drop,setdrop]          = useState('');
  const [editData, setEditData] = useState(editFormData || '');
  const [isEdit,setIsEdit]      = useState(false);
  const [isRenderAgain,setIsRenderAgain] = useState(false);
  const [selecetdSocDetArr,SetSelecetdSocDetArr] = useState([]);
  const [selScopItemList,setSelScopItemList] = useState({});

  useEffect(()=>{
    fetchEditSerialNumber(editFormData?.value)
    // fetchScopeDetails(editFormData?.value)
  },[editFormData])

  const fetchEditSerialNumber = async(id)=>{
    if(id){
        // let res = await fecthSrNo(id);
        let result  = await GetSrNOById(id);
        if(result){
            console.log(result)
            console.log(editFormData)
            // SetSelecetdSocDetArr()
            console.log(result?.scope_item_id)
            await fetchScopeDetails(result?.scope_item_id,result?.project_id)
            
        }
        // console.log(res)
        // console.log(id)
        // if(res){
        //     setEditData(res)
        // }
        // console.log(res)
    }
  }


  const FetchProjectList = async ()=>{

    const min = 1;
    const max = 100;
    const rand = min + Math.random() * (max - min);
    // setIsRenderAgain(rand)

    let result = await FetchProjects();
    if(result){
      let arr = [];
      for (let index = 0; index < result.length; index++) {
        const element = result[index];
        let obj = {
          value : element.pn_id,
          label:element.project_name
        }
        arr.push(obj);
      }
      setProjectList(arr);
      HandleProjectList(arr?.[0])
    }
  }

  const FetchScopeItems = async (val)=>{
    let result = await fecthScopItem(val);
    if(result){
      let arr = [];
      for (let index = 0; index < result.length; index++) {
        const element = result[index];
        let obj = {
          value : element.id,
          label:element.scope_item_title
        }

        if(editFormData?.data.scope_item_title===element.scope_item_title){
            setSelScopItemList(obj)
            setSelectedScopItem(obj)
        }

        arr.push(obj);
      }



      setScopeItemsList(arr);
    }
 
  }
  
    useEffect(()=>{
    let item =   ScopeItemsList.find(d=>d.label == editData?.data?.scope_item_title)
      handleScopItem(item)
    },[editData?.data?.scope_item_title])
    
    useEffect(()=>{

        // return;
        // if(!ScopeDet){
        //     return;
        // }
        // let ids = ScopeDet.filter((d)=>
        // editData?.data?.scope_detail_id?.includes(d?.value)
        // )
        // console.log(ids,ScopeDet)
        setEditData({...editData,data:{
          ...editData.data,
        }})

    //     let idsList  = '';
    //     for (let index = 0; index < ids.length; index++) {
    //       const element = ids[index].value  ; 
    //       idsList += element;
    //       if((index+1) < ids.length){
    //         idsList += ',';
    //       }
    //     }   
    //   setSelectedScopDet(idsList);
    },[editData?.data?.scope_detail_id])

    // const [selectedScopDet]
    const fetchScopeDetails  = async (ScopItem,sProject)=>{
        let sendObj = {
            "projectId": sProject,
            "scopeItemId":ScopItem
        }

        if(!sendObj.projectId){
            return;
        }
        if(!sendObj.scopeItemId){
            return;
        }

    // console.log(sendObj,'here we are with function')
        let result = await fecthScopDetails(sendObj);
        if(result){

        let arr = [];
        for (let index = 0; index < result.length; index++) {
            const element = result[index];
            let obj = {
                value : element.id,
                label : element.scope_detail_title
            }
            arr.push(obj);
        }

        
      let ids = arr.filter((d)=>
            editFormData?.data?.scope_detail_id?.includes(d?.value)
        )

        SetSelecetdSocDetArr(ids)
        HandleScopDetails(ids);
        setScopeDet(arr)
        }
    }


    const HandleProjectList = async (singleProject) => {
        FetchScopeItems(singleProject.value);
        setSelectedProject(singleProject.value)
        setdrop(singleProject.label)
    }

    const handleScopItem = async (ScopItem) => {
        fetchScopeDetails(ScopItem?.value,selectedProject);
        setSelectedScopItem(ScopItem);
        setSelScopItemList(ScopItem);
        // setEditData({...editData,data:{
        // ...editData.data,
        // scope_item_title :ScopItem?.label  
        // }})
    }

    const HandleScopDetails = (ScopDetails) => {
        let idsList  = '';
        let idListArr = [];
        for (let index = 0; index < ScopDetails.length; index++) {
        const element = ScopDetails[index].value  ; 
        idsList += element;
        idListArr.push(ScopDetails[index]);
        if((index+1) < ScopDetails.length){
            idsList += ',';
        }
        }   
        setSelectedScopDet(idsList);
        SetSelecetdSocDetArr(idListArr)
        // setEditData({...editData,data:{
        // ...editData.data,
        // scope_detail_id : idsList,
        // }})
    }

  // submit main form
    const handleSubmit = async (e)=>{
    e.preventDefault();
    
    

    if(!selectedProject){
      setFormMsg({type:false,msg:'Please Select Project'})
      return;
    }
    
    if(!SelectedScopItem){
      setFormMsg({type:false,msg:'Please Select Scope Items'})
      return;
    }
    
    if(!selectedScopDet){
      setFormMsg({type:false,msg:'Please Select Scope Details'})
      return;
    }
    setLoading(true);

    let sendObj = {
    "projectId": selectedProject,
    "scopeItemId": SelectedScopItem.value,
    "scopeDetailId": selectedScopDet,
    "serial_number": e.target.serial_number.value,
    "serialId" :editFormData.value
    }
    // let saveForm;

    // console.log(editFormData);
    
    let saveForm = await CallPOSTAPI('update-serial-number',sendObj);
    await handleResponse(saveForm);

    // if(edit) {
    //   sendObj.serialId = edit
    //   let saveForm = await CallPOSTAPI('update-serial-number',sendObj);
    //   await handleResponse(saveForm);
    // }else {
    //   let saveForm = await CallPOSTAPI('save-serial-number',sendObj)
    //   await handleResponse(saveForm);
    // }
    // console.log('Here we are ',saveForm);
    // alert('Hello')
    // return;

    // console.log(saveForm)
    // if(saveForm.status){
      // if(saveForm?.data?.status){
      //   toast.success(saveForm?.data?.msg);
      //   setLoading(false)
      //   setEditData({})
      //   setEdit(false)
      //   setFormMsg({type:saveForm?.data?.status,msg:saveForm?.data?.msg})
      //   FetchProjectList();
      //   return;
      // }else{
      //   toast.error(saveForm?.data?.msg);
      //   setLoading(false)
      //   setEditData({})
      //   setEdit(false)
      //   setFormMsg({type:saveForm?.data?.status,msg:saveForm?.data?.msg})
      //   FetchProjectList();
      //   return;
      // }
    // }
    // setLoading(false);
    }


    const handleResponse = async (saveForm)=>{
        if(saveForm?.data?.status){
          toast.success(saveForm?.data?.msg);
          setLoading(false)
        //   setEditData({})
        //   setEdit(false)
          setFormMsg({type:saveForm?.data?.status,msg:saveForm?.data?.msg})
        //   FetchProjectList();
          return;
        }else{
          toast.error(saveForm?.data?.msg);
          setLoading(false)
        //   setEditData({})
        //   setEdit(false)
          setFormMsg({type:saveForm?.data?.status,msg:saveForm?.data?.msg})
        //   FetchProjectList();
          return;
        }
    }

  // Start In Begning
  useEffect(()=>{
    FetchProjectList();
    // setIsEdit(false);
    // setEditData('');
  },[navigate])

  return (
    <>
        <img src={logo} className={'form-brand'} />
        <form  onSubmit={handleSubmit} id="save-srno-form">

              <div>
                <Autocomplete
                  id="combo-box-demo"
                  value={drop}
                  options={ProjectList}
                  onChange={(event, newValue) => HandleProjectList(newValue) }
                  sx={{ width: '100%' }}
                  renderInput={(params) => <TextField {...params} label="Select Project" />}
                  />

<p className='tip-box'> <b>Tip: </b>{window?.GLOBAL_TIPS?.serial_number_form?.select_project}</p>

              </div>

              
            {showDropDown && isEdit===false ?  ""
             : 
             <>
             <h2 className={styles.heading}>Enter Serial Number</h2>
            <div className='' >
{/* {JSON.stringify(editData)} */}
              <TextField
                        className={styles.userNameTextField}
                        sx={{ width: 400 }}
                        color="primary"
                        variant="filled"
                        type="text"
                        label="Enter Serial Number"
                        size="medium"
                        margin="none"
                        required
                        name="serial_number"
                        value={editData?.data?.serial_number||""}
                        onChange={(event, newValue) => setEditData({...editData,data:{
                          ...editData.data,
                          serial_number :event.target.value
                        }}) }  
                      />
<p className='tip-box'> <b>Tip: </b>{window?.GLOBAL_TIPS?.serial_number_form?.serial_number}</p>

            </div>

            <div>
              <Autocomplete
              id="Scope-items-list"
            //   value={editData?.data?.scope_item_title||""}
              value={selScopItemList}
              getOptionSelected={(option, value) => option.label === value.label}
              options={ScopeItemsList}
              disabled={ScopeItemsList.length?false:true}
              onChange={(event, newValue) => handleScopItem(newValue) }
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} label="Scope Item List" />}
              />

<p className='tip-box'> <b>Tip: </b>{window?.GLOBAL_TIPS?.serial_number_form?.scope_item_title}</p>

            </div>
<div>

            <Autocomplete
              id="Scope-details-list"
              value={selecetdSocDetArr}
              options={ScopeDet}
              getOptionSelected={(option, value) => option.label === value.label}
              disabled={ScopeDet.length?false:true}
              onChange={(event, newValue) => HandleScopDetails(newValue) }
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} label="Scope Details List" />}
              multiple
              />

            <p className='tip-box'> <b>Tip: </b>{window?.GLOBAL_TIPS?.serial_number_form?.scope_details_list}</p>

            </div>       


        <MessageHandler status={FormMsg.type} msg={FormMsg.msg} HandleMessage={setFormMsg} />

        {edit && <Button type="button" onClick={()=> setEditData('')} >Cancle</Button>}
        
        <Button className={styles.button}
          endIcon={<Icon>arrow_forward_ios_sharp</Icon>}
          type="submit"
        >
          {loading ? "Loading..."  : "Save Serial Number"}

        </Button>

       


            </>
             }
        </form>
    </>
  );
};

export default EdistErNoForm;
