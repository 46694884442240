import React,{useState,useEffect} from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };



export default function SrDetailModals({openModal,closeModal}) {

const [open, setOpen] = React.useState(false);

const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);

  return (
    <>
        <div className='' > 
        <Modal
            open={openModal}
            onClose={closeModal}
            aria-labelledby="srno-title"
            aria-describedby="srno-description"
        >
        <Box sx={style}>
          <Typography id="srno-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="srno-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>


        </div>
    </>
  )
}
