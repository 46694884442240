import { TextField, Input, Icon, Button } from "@mui/material";
import styles from "./Dashboard.module.css";

import Navbar from "../../components/Navbar/Navbar"
import { Link } from "react-router-dom";
import { DecryptToken, UserDetails } from "../../helper/BasicFn";
import React from "react";
import Logo from '../../img/hydro-logo.png';
import { Statistic } from 'semantic-ui-react'
import { CallGETAPI } from "../../helper/API";

const AllClients = () => {
  const [userInfo,setUserInfo] = React.useState({});
  const [dashboardContent,setDashboardContent]  = React.useState([]);
  const [isActive,setIsActive] = React.useState(false);

  const fecthData = async()=>{
    let res = await CallGETAPI('get-created-projects');
    if(res?.data.status){
      setIsActive(true)
      setDashboardContent(res?.data)
    }
  }
  React.useState(()=>{
    let userInfoData = DecryptToken();
    setUserInfo(userInfoData)
    fecthData();
  },[])

 
  return (
    <>

    <div className={styles.DashboardContainer}>
      <main className={styles.main}>
        <img src={Logo} className={'form-brand'} />
        <span className={styles.welcomeText}>Authorized Clients</span>
    
        <div className="col-12" >
                <p>The list of clients you are authorized to work on. Contact admin for assistance</p>
            </div>

        
      </main>
    </div>


    </>
  );
};

export default AllClients;
