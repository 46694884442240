import React, { useEffect,useState } from 'react'
import './Navbar.css'
import Logo from '../../img/hydro-logo.png'
import user from '../../img/user.png'
import { Link, useNavigate } from 'react-router-dom'
import { CallGETAPI, IMG_URL } from '../../helper/API'
// import {Link} from 'react-scroll'
import { useSelector, useDispatch } from 'react-redux'
import { userProfile } from '../../redux/Slices/ProjectSlice'
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function Navbar() {

    const navigate          = useNavigate()
    const profilePic        = useSelector((state) => state.project.profilePic)
    const disptach          = useDispatch();
    const [file,setFile]    = useState(profilePic);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        console.log(event.currentTarget)
        // form-brand
        setAnchorEl(event.currentTarget);
      };

    const handleClose = () => {
      setAnchorEl(null);
    };

    
    const LogOut  = ()=>{
        sessionStorage.clear();
        navigate('/');
    }
    const fetchData = async() => {
        let result = await CallGETAPI("user-profile");
        if (result.status) {
        //   setFile(IMG_URL+''+result?.data?.data[0].profile_pic);

        // if(result?.data?.data[0].profile_pic!==null){
        //     disptach(userProfile(IMG_URL+''+result?.data?.data[0].profile_pic))
        // }
        
      if(result?.data?.data[0].profile_pic!==null && result?.data?.data[0].profile_pic != 'null' ){
        // setFile(IMG_URL+''+result?.data?.data[0].profile_pic);
        disptach(userProfile(IMG_URL+''+result?.data?.data[0].profile_pic))
      }
        }
      }
      useEffect(()=>{
        console.log('here we are ')
        fetchData();
      },[])
  return (
    <div className="ant-nav-wrapper" id='Navbar'>
        <div className="ant-nav-left">
            <div className="ant-nav-logo" >
                <Link className='menu' spy={true} to='/dashboard' smooth={true}>
                    <img src={Logo} className='main-logo-img1' />
                </Link>
            </div>
        </div>
        <div className="ant-nav-right">
            <div className="ant-nav-list">
                <ul>

                    <Link className='menu' spy={true} to='/dashboard' smooth={true}>
                        <li>
                            Home
                        </li>
                    </Link>
                    {/* <Link className='menu' spy={true} to='/profile' smooth={true} >
                        <li>My Profile</li>
                    </Link> */}
                    <Link className='menu' spy={true} to='/my-project' smooth={true}>
                        <li>
                            My Projects
                        </li>
                    </Link>
                    <Link className='menu' spy={true} to='/create-serial-number' smooth={true}>
                        <li>Serial Numbers</li>
                    </Link>

                    {/* <a className='menu' spy={true} to='create-project' smooth={true}>
                        <li>Generate QA/QC List</li>
                    </a> */}

                    <span className='menu link a-tag' spy={true} to='Howtohire' smooth={true} onClick={LogOut} >
                        <li>Logout</li>
                    </span>
                    
                </ul>
                <MenuIcon onClick={handleClick}/>
            </div>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>Logout</MenuItem>
            </Menu>
            
                
            
            <div className="ant-nav-logo">
                <Link className='menu' spy={true} to='/profile' smooth={true}>
                    <img src={profilePic || user} className="navbar-profile" alt="profile-pic" />
                </Link>
            </div>
        </div>
    </div>
  )
}

export default Navbar