import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CallGETAPI, CallPOSTAPI } from "../../helper/API";
import { Button, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { Button } from "semantic-ui-react";
import { Modal,Box } from "@mui/material";
// import styles from "./Forgot.module.css";
import { IMGURL2 } from "../../helper/API";

export default function SrDetails() {
  const [SRList, setSRList]     = useState([]);
  const [que, setQue]           = useState([]);
  const [viewMore, setviewMore] = useState([]);
  const [count, setCount]       = useState(0);
  const [pop, setpop]           = useState(false);
  const [quesImg,setQuesIng]    = useState([])
  const navigate = useNavigate();
  
  const { id } = useParams();

  useEffect( () => {
    fetchData()
  }, [id]);

  const fetchData = async() =>{
    let result = await CallGETAPI("get-questions/" + id);
    setQue(result?.data?.data?.ques_data || []);
    setQuesIng(result?.data?.data?.img_data || [])
  }

  useEffect( () => {

    if(que.length > 0){
      console.log(que)
      setviewMore([...viewMore,...que.slice(count,count + 5)])
    }

  }, [count,que]);


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [imgModal,setImgModal] = useState(false);
  const [modalImg,setModalImg] = useState('');
  // const []
  const HandleImg = (img)=>{
    setImgModal(true);
    setModalImg(img)
  }
  const closeImgModal = ()=>{
    setImgModal(false);
  }
  return (
    <>
      <div style={{display: "flex", justifyContent: "space-between", marginTop:"5rem"}}>
          <div className="col-6">
          <h3>Sr. Number Details : {id}</h3>
          </div>
          <div className="col-6">
          <Button onClick={()=> navigate(-1)} variant="contained"
          color="primary"
          type="button">Back</Button>
          </div>
        </div>
      {/* <div className="section-title">
        <div className="row">
        <div className="col-md-12">
          <div className="col-md-6"><h3>Sr. Number Details : {id}</h3></div>
          <div className="col-md-6"><h3>Sr. Number Details : {id}</h3></div>
          </div>
        </div>
        
        <Button onClick={()=>{navigate('/dashboard')}} variant="contained"
          color="primary"
          type="submit" style={{float: "right"}}>Back</Button>
      </div> */}

      <div>
            { viewMore?.map((data) => {
              return (
                // <Accordion>
                //   <AccordionSummary
                //     expandIcon={<ExpandMoreIcon />}
                //     aria-controls="panel2a-content"
                //     id="panel2a-header"
                //   >
                <div >
                  <hr/>
                  {/* {JSON.stringify(data)} */}
                    <Typography>{data.question_title}</Typography>
                    <Typography><b>Details:</b><p>{data.question_description}</p></Typography>
                    <Button onClick={()=>setpop(data.how_to_level)}>How to Check</Button>
                    

                    <div className="image-gallery" >
                      {quesImg.map((img)=>{
                        if(data.id === img.question_id){
                          return (
                            <div className="" >
                              <img src={IMGURL2+img.image_name} class='qus-img' onClick={()=>HandleImg(IMGURL2+img.image_name)}  />
                            </div>
                          ) 
                        }
                      } )}
                    
                    </div>
                    </div>
                  // </AccordionDetails>
                // </Accordion>
              );
            })}



            <Modal
            open={pop}
            onClose={()=>setpop(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
>
<Box sx={style}>
  <div className="how-title" style={{display:'flex', alignItems:'center',justifyContent:'space-between'}} >
  <b>How To Level:</b>
    <Button
            onClick={()=>setpop(false)}
            sx={{fontSize:'30px'}}
            >&times;</Button>
  </div>
<Typography><span dangerouslySetInnerHTML={{__html:pop}}></span></Typography>
</Box>
</Modal>



<Modal
  open={imgModal}
  onClose={closeImgModal}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>

    <div className="d-flex modal-header" >

    <Typography id="modal-modal-title-ques" variant="h6" component="h2">
      Question Image
    </Typography>

    <Button
            onClick={closeImgModal}
            sx={{fontSize:'30px'}}
            >&times;</Button>

            </div>
    <Box>
          <img src={modalImg}  width="100%" height="auto" />
    </Box>
  </Box>
</Modal>

            {viewMore.length<que.length ? <div onClick={()=>setCount(count+5)}>viewMore</div>:""}
      </div>
    </>
  );
}
