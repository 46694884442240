import './SinglePorject.scss'
import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import SearchIcon  from  '../../img/search.png';

export default function SingleProject() {
    const [SrNo,setSrNo] = React.useState([
        {srno: 'S.no.12314134518',id: '12'},
        {srno: 'S.no.12314134518',id: '12'},
        {srno: 'S.no.12314134518',id: '12'},
        {srno: 'S.no.12314134518',id: '12'},
        {srno: 'S.no.12314134518',id: '12'},
        {srno: 'S.no.12314134518',id: '12'},
        {srno: 'S.no.12314134518',id: '12'},
        {srno: 'S.no.12314134518',id: '12'},
        {srno: 'S.no.12314134518',id: '12'},
        {srno: 'S.no.12314134518',id: '12'},
        {srno: 'S.no.12314134518',id: '12'},
        {srno: 'S.no.12314134518',id: '12'},
        {srno: 'S.no.12314134518',id: '12'},
        {srno: 'S.no.12314134518',id: '12'},
        {srno: 'S.no.12314134518',id: '12'},
        {srno: 'S.no.12314134518',id: '12'},
        {srno: 'S.no.12314134518',id: '12'},
        {srno: 'S.no.12314134518',id: '12'},
        {srno: 'S.no.12314134518',id: '12'},
        {srno: 'S.no.12314134518',id: '12'},
    ])
  return (
    <div>
        <div className='single-project' >
            <div className='section-header'>
                <div className='left' >
                    <h1  className="section-title">Project Name Details</h1>
                    <small className='client-name'>Show Client Name</small>
                </div>
                <div className='right' >
                    <button className='export-btn' >Export CSV</button>
                </div>
            </div>

            <div className='search-box' >
                <img src={SearchIcon} alt="Search-Box"  className='search-img' />
                <input type="search"  className="search-input" id="search-input" placeholder='Search by Serial Number, Scope Item or Scope Details' name="search_input"   />
            </div>
            <div className='flex-box' >
                {SrNo.map((SingleSr,index)=>(
                    <div className='box' key={index} >
                        <h4>{SingleSr.srno}</h4>
                        <div className='foot-box' >
                            <a className='link' >View Details</a>
                        </div>
                    </div>
                ))}
                
            </div>
        </div>
    </div>
  )
}
