import React from 'react';
import { TextField, Input, Icon, Button } from "@mui/material";
import styles from "./Forgot.module.css";
import MessageHandler from "../../components/MessageHandler";
import { CallGETAPI, CallPOSTAPI } from '../../helper/API';
import logo from '../../img/hydro-logo.png';
import {  useNavigate, Link } from 'react-router-dom';

const ForgotPassword = () => {

  const [FormMsg,setFormMsg] = React.useState({type:true,msg:""});
  const [loading,setLoading] = React.useState(false);
const navigate = useNavigate()
  const HandleSubmit = async (e)=>{
    e.preventDefault();
    setLoading(true)
    
    const Email = e.target.Email.value;

    if(!Email){
      setFormMsg({type:false,msg:'Email is required'})
      setLoading(false)
      return;
    }
    
    let senData = {"email":Email}
    let result  = await CallPOSTAPI("forgot-password",senData);

    if(result.status){
      setFormMsg({type:result.status,msg:result.data.msg})
      setLoading(false)
      return;
      // if(result.data.status){
      //   setFormMsg({type:result.status,msg:result.data.msg})
      //   setLoading(false)
      //   return; 
      // }else{
      //   setFormMsg({type:result.status,msg:result.data.msg})
      //   setLoading(false)
      //   return; 
      // }
    }
    setLoading(false)
    setFormMsg({type:false,msg:'Something Went Wrong Please Try Again'})

  }
  return (
    <div>
      <main className={styles.main}>
        <a className={styles.logoA}>
          <div className={styles.yourLogoDiv}>
            <img src={logo} className={'form-brand'} />
          </div>
        </a>
        <span>Forgot Password</span>
        <form className="" id="forgot-password"  onSubmit={HandleSubmit} >
          <div className={styles.field}>
        <TextField
          className={styles.userNameTextField}
          sx={{ width: 499 }}
          color="primary"
          variant="filled"
          type="text"
          label="Email"
          size="medium"
          margin="none"
          name="Email"
          required
        /></div>
        <MessageHandler status={FormMsg.type} msg={FormMsg.msg} HandleMessage={setFormMsg} />

        <div className={styles.btn}>
        <Button
          className={styles.muibtn}
          variant="contained"
          color="primary"
          type="submit"
          endIcon={<Icon>arrow_forward_ios_sharp</Icon>}
        >
          {loading ? "Loading..." : "Submit"}
        </Button>
        </div>
        {/* <p></p> */}
        {/* <Button  className={styles.muibtn} onClick={()=>{navigate('/')}} variant="contained"
          color="primary"
          type="submit">Back</Button> */}
        </form>
        <Link className={styles.forgotPassword} to="/">
          Back
        </Link>

        <div className="">
          <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's </p>
        </div>
       
        
      </main>
    </div>
  );
};

export default ForgotPassword;
