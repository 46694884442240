import { TextField, Button, Icon } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BASE_API, CallGETAPI, CallPOSTAPI, IMG_URL } from "../../helper/API";
import styles from "./profile.module.css";
import MessageHandler from "../../components/MessageHandler";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ResetPassword from "./resetPassword";
import user from '../../img/user.png'

import EditPassword from "./editPassword";
import ImageUploading from 'react-images-uploading';

import { useSelector, useDispatch } from 'react-redux'
import { userProfile } from '../../redux/Slices/ProjectSlice'

const Profile = () => {
  const [data, setData] = useState();
  const [editData, seteditData] = useState({username:"",email:"",mobile_no:""});
  const [profile, setProfile] = useState({username:"",email:"",mobile_no:""})
  const [FormMsg, setFormMsg] = useState({ type: false, msg: "" });
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState()

  
  const profilePic    = useSelector((state) => state.project.profilePic)
  const disptach = useDispatch();


  const navigate = useNavigate();

  
const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
          resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
          reject(error);
      };
  });
};

const uploadImage = async (event) => {
  const file = event.target.files[0];
  const base64 = await convertBase64(file);
  avatar.src = base64;
  textArea.innerText = base64;
};


const [base64Img,setBase64Img] = useState('');
const [currentProfilePic,setCurrentProfilePic] = useState('');

async  function handleChange(event) {
    let fileReader = new FileReader();
    let myImg = fileReader.readAsDataURL(event.target.files[0]);
    
    const base64 = await convertBase64(event.target.files[0]);
    setBase64Img(base64);
    // var reader = new FileReader();
    // var url = reader.readAsDataURL(event.target.files[0]);
    setFile(base64)


  }

const handleSubmit = async (e)=>{
  e.preventDefault();

  if(editData.username == "" && editData.email == "" && editData.mobile_no == ""){
    setFormMsg({
        type:false,
        msg:'Any field not must be emty'
    })
    return
  }if(editData.username == ""){
    setFormMsg({
        type:false,
        msg:'Username not must be emty'
    })
    return
  }if(editData.email == ""){
    setFormMsg({
        type:false,
        msg:'Email not must be emty'
    })
    return
  }if(editData.mobile_no == ""){
    setFormMsg({
        type:false,
        msg:'Mobile no. not must be emty'
    })
    return
  }
  let sendOj = {
    profilePic: base64Img,
    username: editData.username,
    mobile:editData.mobile_no
  }
  let result = await CallPOSTAPI('update-profile',sendOj)
  if(result.status){
    setFormMsg({type:result.status,msg:'Profile Updated Successfully'})
  }else{
    setFormMsg({type:result.status,msg:result.msg})
  }
  fetchData();
}
  const handleSubmit2 = async(e) => {
    e.preventDefault();
  if(editData.username == "" && editData.email == "" && editData.mobile_no == ""){
    setFormMsg({
        type:false,
        msg:'Any field not must be emty'
    })
    return
  }if(editData.username == ""){
    setFormMsg({
        type:false,
        msg:'Username not must be emty'
    })
    return
  }if(editData.email == ""){
    setFormMsg({
        type:false,
        msg:'Email not must be emty'
    })
    return
  }if(editData.mobile_no == ""){
    setFormMsg({
        type:false,
        msg:'Mobile no. not must be emty'
    })
    return
  }
  let formData = new FormData();
  
  formData.append('img', file);
  formData.append('username', editData.username);
  formData.append('mobile', editData.mobile_no);
  //  image kaha se add kr rhe ho ?

  let token  = sessionStorage.getItem('qc_token');
  // 'Content-Type': 'multipart/form-data; boundary=5',

  const config = {
    Authorization:  'Bearer '+token,
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*'
  };
  // let result = await CallPOSTAPI('update-profile',null,config,formData);
  // let headers  = {
  // }
  let result  = await fetch(BASE_API+'update-profile',{
    method: 'POST',
    headers: config,
    body: formData
  })
  let res = await result.json();
  if(res.status){
    if(res.data.status){
      toast.success("Profile Edit sucessfully");
      setLoading(false)
      fetchData()
      return;
    }
    setFormMsg({type:res.status,msg:"Profile Edit sucessfully"})
  }
  }

  useEffect( () => {
    fetchData()
  }, []);

  const fetchData = async() => {
    let result = await CallGETAPI("user-profile");
    if (result.status) {
      setData(result?.data?.data[0]);
      seteditData(result?.data?.data[0]);
      if(result?.data?.data[0].profile_pic!==null && result?.data?.data[0].profile_pic != 'null' ){
        setFile(IMG_URL+''+result?.data?.data[0].profile_pic);
        disptach(userProfile(IMG_URL+''+result?.data?.data[0].profile_pic))
      }
    }
  }
  

 
  return (
    <div className={styles.loginDiv}>
      <main className={styles.main}>
        <div className={styles.container}>
          <div className={styles.flex}>
          <div className={styles.formDiv}>
            <div className={styles.grid} >
              <form className={styles.profileFrom} onSubmit={handleSubmit}  >
                  <h2 className={styles.heading}>User Profile</h2>
                  
                  <div className={styles.titlediv} >
                    
                    <label htmlFor="profileImg" className={styles.profileLabel}>
                      <div className={styles.profileDiv} >
                        <img src={file || user}  className={styles.profilePic} />
                        <label htmlFor="profileImg" className={styles.plusIcon} >&#x2b;</label>
                      </div>  
                      Profile Pic
                    </label>
                    <input type="file" id="profileImg" name="img" accept="image/*" onChange={handleChange} />
                  </div>

                  <div className={styles.titlediv}>
                    {/* <div className={styles.title}>UserName :</div> */}
                  
                    <TextField
                      className={styles.userNameTextField}
                      sx={{ width: "100%" }}
                      color="primary"
                      variant="filled"
                      type="text"
                      name="username"
                      value={editData?.username}
                      size="medium"
                      margin="none"
                      label="Username"
                      onChange={(value) => {
                        seteditData({
                          ...editData,
                          username: value.target.value,
                        });
                      }}
                      // disabled
                    />
                  </div>

                  <div className={styles.titlediv}>
                    {/* <div className={styles.title}>Email :</div> */}
                    <TextField
                      className={styles.userNameTextField}
                      sx={{ width: "100%" }}
                      color="primary"
                      variant="filled"
                      type="email"
                      name="email"
                      label="Email"
                      value={editData?.email}
                      size="medium"
                      margin="none"
                      disabled
                    />
                  </div>

                  <div className={styles.titlediv}>
                    {/* <div className={styles.title}>Mobile No :</div> */}
                    <TextField
                      className={styles.userNameTextField}
                      sx={{ width: "100%" }}
                      color="primary"
                      variant="filled"
                      type="number"
                      label="Mobile"
                      name="mobile_no"
                      value={editData?.mobile_no}
                      size="medium"
                      margin="none"
                      onChange={(value) => {
                        seteditData({
                          ...editData,
                          mobile_no: value.target.value,
                        });
                      }}
                    />
                  </div>

                  <MessageHandler
                    status={FormMsg.type}
                    msg={FormMsg.msg}
                    HandleMessage={setFormMsg}
                  />
                  <Button className={styles.button1}  type='submit' endIcon={<Icon>arrow_forward_ios_sharp</Icon>}>
                    {loading ? "Loading..." : "Update Profile"}
                  </Button>
              </form>
            </div>
          </div>


          <div className={styles.formDiv}>
            <EditPassword />
          </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Profile;
