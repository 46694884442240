import React from 'react';
import EYEIcon from '../../img/eye.png';
import EditIcon from '../../img/edit.png';
import TrashIcon from '../../img/trash.png';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import DataGrid, { Column,SearchPanel } from 'devextreme-react/data-grid';
import EditProject from './EditProject';
import { useNavigate } from 'react-router-dom';
import { CallGETAPI } from '../../helper/API';
import Swal from 'sweetalert2';


// import { DataGrid } from '@mui/x-data-grid';

export default function ProductTable({TableTile,TableData,clientList,GetProjects,setEditData,setEdit,openEditModal,setOpenEditModal}) {
    const navigate = useNavigate();
    const [GridData,setGridData] = React.useState([]);

    React.useEffect(()=>{

        if(!TableData) return;

        let CustomArr = [];
        for (let index = 0; index < TableData.length; index++) {
            const element = TableData[index];
            let obj = {
                id: element.pn_id,
                project_name: element.project_name,
                client_name: element.client_name,
                program_name: element.program_name,
                created_at: element.created_at,
            }
            CustomArr.push(obj)
        }
        setGridData(CustomArr)
    },[TableData])

    


    const [EditProgram,setEditProgram] = React.useState('');
    // const [openEditModal,setOpenEditModal] = React.useState(false);

    const MakeEdit = (eid,Data)=>{
        setOpenEditModal(true);
        setEditProgram(eid);

        // console.log(Data)
        let editObj = {
            label: Data.client_name,
            value: Data.pn_id,

            pn_id: Data.pn_id, 
            program_name: Data.program_name,
            project_name: Data.project_name,
        }
        setEditData(editObj)
        setEdit(eid)
        window.scrollTo({top:0,behavior:"smooth"})
    }

    const askAlert  = (did,ptitle)=>{
        Swal.fire({
            title: 'Are you sure?',
            text: "if you are sure you want to delete project '"+ptitle+"'",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {

                MakeDelete(did)
            //   Swal.fire(
            //     'Deleted!',
            //     'Your file has been deleted.',
            //     'success'
            //   )
            }
          })
    }

    const MakeDelete =  async (did)=>{

        // let check = confirm('Are You Sure?, You want to Delete');
        // if(check){
            let result = await CallGETAPI('delete-project-name/'+did);
       
            if(result?.data?.status){
                toast.success(result?.data?.msg)
                await GetProjects();
            }else{
                toast.error(result.data.msg)
            }
        // }
    }   

    const ShowDetails = (id)=>{
        navigate('/project-details/'+id)
    }

    const GetAction =  (Data)=>{
        let id =  Data.data['pn_id'];
        let ptitle =  Data.data['project_name'];
        return (<> 
            <div className='d-flex  edit-icons ' >                
            <span className='d-a-tag pointer'  onClick={()=>MakeEdit(id,Data?.data)} title="edit">
                <img src={EditIcon} alt="Edit" />
            </span>
            <span className='a-tag pointer' onClick={()=>ShowDetails(id)} title="view">
                <img src={EYEIcon} alt="Eye" />
            </span>
            <span className='a-tag pointer' onClick={()=>askAlert(id,ptitle)} title="remove">
                <img src={TrashIcon} alt="Trash" />
            </span>
            </div>
            </>)
    }

  return (
    <>
    {/* styles.cardHeader */}
    <div className={'table-section-div'}>

        <div className="table-header">
            {TableTile}
        </div>        

        <div className='table-div' >

            {GridData.length > 0 && <>
            
        <DataGrid
            dataSource={TableData}
            keyExpr="pn_id"
            showRowLines={true}
            className="data-grid-table"
            columnAutoWidth={true}
            allowColumnReordering={true}
            showColumnLines={true}
            showBorders={false}
            wordWrapEnabled={true}            
        >

            <SearchPanel visible={true} />

            <Column 
                dataField="project_name"
                caption={'Project Name'}
                dataType="string"
                width={220}
            />
            <Column 
                dataField="client_name"
                caption={'Client Name'}
                dataType="string"
                // width={250}

            />
            <Column 
                dataField="program_name"
                caption={'Program Name'}
                dataType="string"

                // width={250}
            />
            
            <Column 
                dataField="created_at"
                caption={'Date'}
                dataType="date"
                width={130}
            />

            <Column 
                caption={'Action'}
                dataType="date"
                cellRender={(data)=>GetAction(data)}
            />

        </DataGrid>
        </>}

          {/* <EditProject openEditModal={openEditModal} setOpenEditModal={setOpenEditModal} EditProgram={EditProgram} clientList={clientList}/> */}
        </div>

        {/* <div className='table-footer'>
            <Link to="/all-projects">
                <a href="" >View All Projects</a>
            </Link>
        </div> */}

    </div>
    </>
  )
}
