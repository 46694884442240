import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../img/logo.png";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import TwentyTwoMpIcon from "@mui/icons-material/TwentyTwoMp";
import LogoutIcon from "@mui/icons-material/Logout";
import Logo from "../../img/hydro-logo.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import user from "../../img/user.png";

import { CallGETAPI, IMG_URL } from "../../helper/API";
import { useSelector, useDispatch } from "react-redux";
import { userProfile } from "../../redux/Slices/ProjectSlice";
import { Button } from "@mui/material";
import { Dropdown } from "semantic-ui-react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  minHeight:'120px',
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Layout(props) {
  const theme = useTheme();
  //   const navigate = useNavigate();
  const [open, setOpen]     = React.useState(true);
  const [drawer, setDrawer] = React.useState(false);
  console.log("🚀 ~ file: index.js:93 ~ Layout ~ drawer", drawer);

  const navigate = useNavigate();
  const profilePic = useSelector((state) => (state.project.profilePic!==null & state.project.profilePic!='null') ? state.project.profilePic :'');
  const disptach = useDispatch();
  const [file, setFile] = React.useState(profilePic);

  const LogOut = () => {
    sessionStorage.clear();
    navigate("/");
  };
  const fetchData = async () => {
    let result = await CallGETAPI("user-profile");
    if (result.status) {
      //   setFile(IMG_URL+''+result?.data?.data[0].profile_pic);
      let pPic = result?.data?.data[0].profile_pic;
      if(pPic!=null && pPic!='null' && pPic!= 'NULL'){
        disptach(userProfile(IMG_URL + "" + result?.data?.data[0].profile_pic));
      }
    }
  };
  React.useEffect(() => {
    fetchData();
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenDrawer = () => {
    console.log("first", "first clicked");
    setDrawer(true);
  };

  const handleCloseDrawer = () => {
    setDrawer(false);
  };

  const NavList = [
    { title: "Home", icon: <DashboardIcon />, link: "/dashboard" },
    { title: "Edit Profile", icon: <DashboardIcon />, link: "/profile" },
    { title: "My Projects", icon: <AccountTreeIcon />, link: "/my-project" },
    { title: "Generate Smart Checklist", icon: <AccountTreeIcon />, link: "/create-project" },

    {
      title: "Enter Scope",
      icon: <TwentyTwoMpIcon />,
      link: "/create-serial-number",
    },
    {
      title: "Edit Scope",
      icon: <TwentyTwoMpIcon />,
      link: "/edit-serial-number",
    },
    {
      title: "Export Checklist",
      icon: <TwentyTwoMpIcon />,
      link: "/edit-serial-number",
    },
    {
      title: "Clients",
      icon: <TwentyTwoMpIcon />,
      link: "/clients",
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const opena = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const Logout = ()=>{
      sessionStorage.clear();
      navigate('/');
  }



  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar className="slider-card">
          <div className="sidebar-header">
            <div className="left-side">
              
            </div>

            {/* <IconButton
              color="#000"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton> */}
          </div>

          <div className="right-side"   >
            <div className="ant-nav-logo" style={{marginTop:'30px'}}>
              <div className="drapdown-style">
                <Dropdown multiple icon={()=>{
                      return (
                        <>
                        <div className="" style={{display:'flex',alignItems:'center'}} >
                          <MenuIcon  sx={{color:'#000',mr:'10px'}}  />

                        <img
                          src={ profilePic || user}
                          className="navbar-profile"
                          alt="profile-pic"
                          onClick={handleOpenDrawer}
                          />
                          </div>
                          </>
                      )
                  }}>
                <Dropdown.Menu className="position-fix-drop">
                  <Dropdown.Menu scrolling >
                  <Dropdown.Item onClick={()=>{
                    navigate('/profile')
                  }}>Profile</Dropdown.Item>
                  <Dropdown.Item onClick={()=>{
                            Logout('/')
                  }}>Log Out</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown.Menu>
                </Dropdown>
              </div>
             
            </div>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader sx={{justifyContent:'center',minHeight:'fit-content'}}
        
        style={{minHeight:'fit-content'}}
        >
            
            <Link className="menu" spy={true} to="/dashboard" smooth={true}
              
            >
                <img src={Logo} className="main-logo-img2" width={'150px'} />
              </Link>

        </DrawerHeader>
        <Divider />
        <List>
          {NavList.map((text, index) => (
            <ListItem key={text.title} disablePadding>
              <Link to={text.link} className="li-a">
                <ListItemButton>
                  <ListItemIcon>{text.icon}</ListItemIcon>
                  <ListItemText primary={text.title} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}

          <ListItem key={"logout"} disablePadding onClick={LogOut}>
            <ListItemButton>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Main open={open} className="main-content">
        <DrawerHeader />

        <div className="content-div" >
          {props.children}
        </div>


        <footer className="site-footer">
          <div className={''}>
            <a className={''} href="#">
              Terms of Use
            </a>
            <a className={''} href="#">
              Privacy Policies
            </a>
            <a className={''} href="#">
              Help and Support
            </a>
            <a className={''} href="#">
              Cookies Policies
            </a>
          </div>
        </footer>
        <ToastContainer/>
      </Main>
    </Box>
  );
}
