import React from 'react'
import CreateProjectForm from './CreateProjectForm'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  
export default function EditProjectModal({openEditModal,setOpenEditModal,formEditData,setFormEditData}) {
    // const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpenEditModal(true);
  const handleClose = () => setOpenEditModal(false);

  return (
    <>

<div>
      <Modal
        open={openEditModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <div className='' style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'space-between'}} >
                <h4>Edit Project </h4>
                <Button type="button" sx={{float:'right'}} onClick={handleClose}>&times;</Button>
            </div>

            <div className='' >
            <CreateProjectForm formEditData={formEditData} setFormEditData={setFormEditData} 
                handleClose={handleClose}
            />
            </div>

          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */}
        </Box>
      </Modal>
    </div>
    </>
  )
}
