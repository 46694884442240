import React from 'react';
import { TextField, Input, Icon, Button } from "@mui/material";
import { Link } from "react-router-dom";
import MessageHandler from "../../components/MessageHandler";
import styles from "./Login.module.css";
import { CallPOSTAPI } from '../../helper/API';
import { useNavigate } from 'react-router-dom';
// import Logo from '../../img/hydro-logo.png'
// import Logo from '../../img/logo.png'

import logo from '../../img/logo.png';
const Login = () => {

  const navigate = useNavigate();
  const [FormMsg,setFormMsg] = React.useState({type:true,msg:""});
  const [loading,setLoading] = React.useState(false);
  

  const HandleSubmit = async (e)=>{
    e.preventDefault();
    setLoading(true)
    
    const Username = e.target.Username.value;
    const Password = e.target.Password.value;

    if(!Username){
      setFormMsg({type:false,msg:'Please Fill User Name'})
      setLoading(false)
      return;
    }

    if(!Password){
      setFormMsg({type:false,msg:'Please Fill Password'})
      setLoading(false)
      return;
    }
    
    let senData = {"email":Username,"password":Password}
    let result  = await CallPOSTAPI("login-user",senData);

    if(result.status){
      if(result.data.status){
        const token = result.data.data;
        sessionStorage.setItem('qc_token',token);
        navigate("/dashboard");
        setLoading(false)
        return;
      }else{
        setFormMsg({type:false,msg:result.data.msg})
        setLoading(false)
        return; 
      }
    }
    setLoading(false)
    setFormMsg({type:false,msg:'Something Went Wrong Please Try Again'})

  }
  return (
    <div className={styles.loginDiv}>
      <main className={styles.main}>
        <div div className="ant-nav-logo" >
            {/* <img src={logo} className='main-logo-img' /> */}
            {/* <img src={logo} className='main-logo-img' /> */}
        </div>
        
            <img src={logo} className={'form-brand'} />
        <form className="" id="login-form"  onSubmit={HandleSubmit} >

        
        <TextField
          className={styles.userNameTextField}
          sx={{ width: 499 }}
          color="primary"
          variant="filled"
          type="text"
          label="Username"
          name="Username"
          size="medium"
          margin="none"
          required
        />
        <TextField
          className={styles.userNameTextField}
          sx={{ width: 504 }}
          color="primary"
          variant="filled"
          type="password"
          name="Password"
          label="Password"
          size="medium"
          margin="none"
          required
        />
        {/* <Link to="dashboard" > */}

        <MessageHandler status={FormMsg.type} msg={FormMsg.msg} HandleMessage={setFormMsg} />

        <Button
          variant="contained"
          color="primary"
          type="submit"
          endIcon={<Icon>arrow_forward_ios_sharp</Icon>}
          
          >
          {loading?' Loading... ':'Login'}
        </Button>
          {/* </Link> */}

        </form>

        {/* <a className={styles.forgotPwdA} href="#" target="_blank" /> */}
        <Link className={styles.forgotPassword} to="/forgot-password">
          Forgot Password?
        </Link>
        
        <div className="">
          <p> {window?.GLOBAL_TIPS?.login} </p>
        </div>

        {/* <text>Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</text> */}
        <div className={styles.bottomLinksDiv}>
          <a className={styles.termsOfUse} href="#">
            Terms of Use
          </a>
          <a className={styles.termsOfUse} href="#">
            Privacy Policies
          </a>
          <a className={styles.termsOfUse} href="#">
            Help and Support
          </a>
          <a className={styles.termsOfUse} href="#">
            Cookies Policies
          </a>
        </div>
      </main>
    </div>
  );
};

export default Login;
