import React from 'react';

export default function MessageHandler({status,msg,HandleMessage}) {
    
    if(!msg){
        return "";    
    }

    const [showMsg,setShowMsg] = React.useState(true);
    React.useEffect(()=>{
        setTimeout(() => {
            setShowMsg(false)
            HandleMessage({type:false,msg:''})
        }, 5000);
    },[msg,status])

  return (

    
    <> 
    {showMsg && <>
        {status ? 
            <div className='success-msg' >
                {msg}
            </div>
        :
        <div className='error-msg' >
            {msg}
        </div>
        }
    </>}
    </> 
  )
}
